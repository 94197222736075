<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="5">
                    <h4>Config System</h4>
              </CCol>
              <CCol md="7">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />
              </CCol>             
          </CRow>
        </CCardHeader><br/>
        <CCardBody>       
        <div class="table-responsive table">
          <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter">


          <template v-slot:cell(actions)="{ item }">
          <b-dropdown variant="info" text="Actions" size="md">
              <router-link :to='"/config/detail/" + item.configName' tag="b-dropdown-item" > Detail </router-link> 
          </b-dropdown>
          </template>
         

          </b-table>
        </div>
        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import AuthService from '@/api/AuthService.js';

 export default {
    name: "users",
    data() {
      return {
        items:[], 
        fields: [   
          {
            name : 'configName',
            key: 'configName',
            label : 'Config Name',
            sortable: true
          },  
        
          {            
            key: 'actions',
            label: 'Actions'         
          }                        
         
          
          ],       
        currentPage: 1,
        perPage: 10,
        filter: null,
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },

        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },

        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        }


      },

    created () { 
       this.checkAccessByRole();
       this.getSystemConfigList();      
    },
    methods: {  

      isSuperAdmin(){
        if(localStorage.getItem('sa')==="false"){  
            this.$router.push('/dashboard');
        }
      }, 
      
      checkAccessByRole() {
        var strRoles  = localStorage.getItem('roles');
        var roles     = strRoles.split(",");
        var isAllowed = roles.includes("ROLE_SUPER_ADMIN") ? true : false;
        if(!isAllowed) { this.$router.push('/dashboard'); }
      },

      getSystemConfigList: function() {
        AuthService.getSystemConfigList().then(resp => { 
        
          let userData = [] ;
          for (var key in resp) {
             userData.push({ configName : key, config : resp[key] });
          }

          userData.sort(function (a, b) {
              return a.configName.localeCompare(b.configName);
          });

          this.items = userData; 

        }, error => {
          this.loading = false;
        });
      }

    },    
 }; 

</script>
<style>
    .table{ overflow-y:scroll; }
</style>